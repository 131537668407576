<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the numbers listed below, determine the number of significant figures.
      </p>

      <p class="mb-0">
        <stemble-latex :content="'a) ' + number1" />
      </p>

      <v-radio-group v-model="inputs.input1" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'b) ' + number2" />
      </p>

      <v-radio-group v-model="inputs.input2" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'c) ' + number3" />
      </p>

      <v-radio-group v-model="inputs.input3" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'d) ' + number4" />
      </p>

      <v-radio-group v-model="inputs.input4" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'e) ' + number5" />
      </p>

      <v-radio-group v-model="inputs.input5" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LatexNumber from '../displayers/LatexNumber';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'SigFigs2',
  components: {
    LatexNumber,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
        {text: '6', value: '6'},
        {text: '7', value: '7'},
        {text: '8', value: '8'},
        {text: '9', value: '9'},
        {text: '10', value: '10'},
      ],
    };
  },
  computed: {
    number1Integer() {
      return this.taskState.getValueBySymbol('arrayVariable1').content;
    },
    number2Integer() {
      return this.taskState.getValueBySymbol('arrayVariable2').content;
    },
    number3Integer() {
      return this.taskState.getValueBySymbol('arrayVariable3').content;
    },
    number4Integer() {
      return this.taskState.getValueBySymbol('arrayVariable4').content;
    },
    number5Integer() {
      return this.taskState.getValueBySymbol('arrayVariable5').content;
    },
    number1() {
      if (this.number1Integer.value === 1) {
        return '$0.00318$';
      } else if (this.number1Integer.value === 2) {
        return '$0.000514$';
      } else if (this.number1Integer.value === 3) {
        return '$0.0838$';
      } else if (this.number1Integer.value === 4) {
        return '$0.0017$';
      } else if (this.number1Integer.value === 5) {
        return '$0.02369$';
      } else {
        return '$0.004183$';
      }
    },
    number2() {
      if (this.number2Integer.value === 1) {
        return '$0.01806$';
      } else if (this.number2Integer.value === 2) {
        return '$0.07501$';
      } else if (this.number2Integer.value === 3) {
        return '$0.00104$';
      } else if (this.number2Integer.value === 4) {
        return '$0.00708$';
      } else if (this.number2Integer.value === 5) {
        return '$0.0007309$';
      } else {
        return '$0.000104$';
      }
    },
    number3() {
      if (this.number3Integer.value === 1) {
        return '$0.1300$';
      } else if (this.number3Integer.value === 2) {
        return '$0.50$';
      } else if (this.number3Integer.value === 3) {
        return '$0.3190$';
      } else if (this.number3Integer.value === 4) {
        return '$0.680$';
      } else if (this.number3Integer.value === 5) {
        return '$0.140$';
      } else {
        return '$0.1380$';
      }
    },
    number4() {
      if (this.number4Integer.value === 1) {
        return '$0.01500$';
      } else if (this.number4Integer.value === 2) {
        return '$0.0160$';
      } else if (this.number4Integer.value === 3) {
        return '$0.0590$';
      } else if (this.number4Integer.value === 4) {
        return '$0.03190$';
      } else if (this.number4Integer.value === 5) {
        return '$0.0050$';
      } else {
        return '$0.00320$';
      }
    },
    number5() {
      if (this.number5Integer.value === 1) {
        return '$0.03040$';
      } else if (this.number5Integer.value === 2) {
        return '$0.009020$';
      } else if (this.number5Integer.value === 3) {
        return '$0.040100$';
      } else if (this.number5Integer.value === 4) {
        return '$0.030010$';
      } else if (this.number5Integer.value === 5) {
        return '$0.0061030$';
      } else {
        return '$0.0510900$';
      }
    },
  },
};
</script>
